import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"

import Hero from "../components/hero"

const TABLET_WIDTH = 769

const IndexPage = () => {

    const [isWide, setIsWide] = useState(false)

    useEffect(() => {
        if (window.innerWidth < TABLET_WIDTH) {
            setIsWide(false)
        } else {
            setIsWide(true)
        }
    }, [])

    return (

            <Hero colour={"danger"} forceVerticalCentre={true}>
                <Fade left={isWide} bottom={!isWide} duration={1500} delay={300} distance="30px">
                    <StaticImage
                        src="../images/burton-peake-white.png"
                        alt="Burton Peake"
                        placeholder="tracedSVG"
                        loading="eager"
                    />
                </Fade>
            </Hero>

    )
}

export default IndexPage
